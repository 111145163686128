import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    console.info('Connected IoT Entity Toggle', this.element)
  }
  toggle() {
    console.info('Toggling IoT Entity', this.element)
    return fetch(`/admin/api/iot/entity/${this.element.getAttribute('data-entity-id')}/toggle`).then((response) => {
      
    })
    $.ajax({
      url: '/admin/iot/toggle_entity',
      type: 'get'
    })
  }
  auto() {
    $.ajax({
      url: '/admin/iot/automation_run',
      type: 'get'
    })
  }
}